




























































































































  import { debounceInput } from '@/shared/helpers'
  import { ICertificationAuthority } from '@/shared/model/certificationAuthority.model'
  import { User } from '@/shared/model/user.model'
  import axios from 'axios'
  import Vue from 'vue'
  import { mapActions } from 'vuex'

  export default Vue.extend({
    name: 'CertificationAuthoritiesTable',

    components: {
      CertificationAuthorityWizard: () => import('@/views/app/certificationAuthority/CertificationAuthorityWizard.vue'),
    },

    data () {
      return {
        options: {} as any,

        dialog: false,
        selectedUser: new User(),

        search: '',
        certificationauthorities: [] as ICertificationAuthority[],
        totalCertificationAuthorities: 0,
        createCertificationAuthorityDialog: false,
        debounce: undefined as number | undefined,
      }
    },

    computed: {
      headers (): any {
        return [
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            text: this.$t('email'),
            value: 'email',
          },
          {
            text: this.$t('tester'),
            value: 'users',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
    },
    watch: {
      options: {
        async handler () {
          await this.getAllCertificationAuthoritiesWithVDataTable()
        },
      },
      search: {
        async handler () {
          this.debounce = debounceInput(this.debounce, this.getAllCertificationAuthoritiesWithVDataTable)
          this.options.page = 1
        },
      },
    },
    methods: {
      ...mapActions('certificationAuthorityStore', {
        deleteCertificationAuthority: 'deleteItem',
      }),
      async getAllCertificationAuthoritiesWithVDataTable () {
        const query = {
          l: this.options.itemsPerPage,
          lo: this.options.page,
          sf: this.options.sortBy[0],
          so: this.options.sortDesc[0] ? 'desc' : 'asc',
          search: { search: this.search },
        }
        await this.getList(query)
      },
      async getList ({ l, lo, sf, so, search = {} }: any) {
        try {
          // The actual limitOffset is calculated in the api
          const result = await axios.get('/api/certificationauthorities', {
            params: { l: l, lo: lo - 1, sf, so, ...search },
          })
          if (result && result.data && result.data.items) {
            this.certificationauthorities = result.data.items
            this.totalCertificationAuthorities = result.data.total
          }
        } catch (err) {
          console.error(err)
        } finally {
        }
      },
      certificationAuthorityCreated () {
        this.createCertificationAuthorityDialog = false
        this.getAllCertificationAuthoritiesWithVDataTable()
      },
      clickUpdateCertificationAuthority (item: ICertificationAuthority) {
        this.$router.push(`/app/certification-authorities/${item._id}`, () => {})
      },
      clickDeleteCertificationAuthority (item: ICertificationAuthority) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-certification-authority-to')}
<strong>${item.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('delete-certification-authority').toString(),
          },
        ).then(async res => {
          if (res) {
            if (item._id) {
              await this.deleteCertificationAuthority({ id: item._id })
              this.getAllCertificationAuthoritiesWithVDataTable()
            } else {
              console.error('Tried to delete user without id, this should not happen')
            }
          }
        })
      },
    },
  })
